import React from "react"
import {StaticImage} from "gatsby-plugin-image"
import App from '../../components/app'
import BannerLayout from '../../components/banner-layout'
// import BannerLayout from '../../components/banner-layout'
import Encomendar from '../../components/encomendar'

import ogImage from "../../assets/images/lasanha-preparacao-01.jpg"

const title = "Lasanhas da Lini"
const description = "Uma boa lasanha começa com produtos frescos de qualidade. Tomate fresco, alho, cebola e coentros. Também importante é o queijo parmesão ralado na hora. Um copo de vinho tinto no molho não pode faltar. Bom apetite!"

const BannerImage = <StaticImage src="../../assets/images/lasanha-preparacao-01.jpg" alt={title} className="uk-height-medium uk-animation-fade"/>

export default function Lasanha({location}) {
  return <App location={location} title={title} description={description} image={ogImage}>
    <BannerLayout title={title} bannerImage={BannerImage}>
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-text-center">
          <p className="uk-text-lead">{description}</p>
          <Encomendar/>
        </div>
      </section>

      <section className="uk-section uk-section-small">
        <StaticImage className="uk-animation-fade" src="../../assets/images/lasanhas-tradicionais-01.jpg" alt="Lasagnas tradicionais da Lini" objectFit="contain"/>
      </section>

      <section className="uk-section uk-section-small">
        <div className="uk-container">
          <table className="uk-table uk-table-middle uk-table-divider uk-table-hover uk-background-default">
            <thead>
              <tr>
                <th>Tamanho</th>
                <th className="uk-text-right">Preço</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Uma pessoa (ca. 450 g):</td>
                <td className="uk-text-right">4,90 €</td>
              </tr>
              <tr>
                <td>Duas pessoas (ca. 900 g):</td>
                <td className="uk-text-right">8,90 €</td>
              </tr>
              <tr>
                <td>Quatro pessoas (ca. 1.800 g):</td>
                <td className="uk-text-right">15,90 €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="uk-section uk-section-small">
        <div className="uk-container uk-text-center">
          <Encomendar/>
        </div>
      </section>

      <section className="uk-section uk-section-small uk-text-small">
        <div className="uk-container">
          <p>Ingredientes: Cebola, alho, tomate, massa, queijo parmesão, leite, farinha de trigo, sal, pimenta, vinho.</p>
        </div>
      </section>
    </BannerLayout>
  </App>
}
